import Quagga from '@ericblade/quagga2';
import {Check, Close} from '@mui/icons-material';
import {Box, Button, Grid, Link, Typography} from '@mui/material';
import moment from 'moment';
import React, {useEffect} from 'react';
import Geocode from 'react-geocode';
import {useFormContext, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import Storage from 'src/security/storage';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import PerfilEmpleadoAutocompleteFormItem from 'src/view/perfilEmpleado/autocomplete/PerfilEmpleadoAutocompleteFormItem';
import Spinner from 'src/view/shared/Spinner';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputMultiSelectorFormItem from 'src/view/shared/form/items/InputMultiSelectorFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import InputSignatureFormItem from 'src/view/shared/form/items/InputSignatureFormItem';
import MultiCheckboxFormItem from 'src/view/shared/form/items/MultiCheckboxFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import Message from 'src/view/shared/message';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';
import TerceroAutocompleteFormItem from 'src/view/tercero/autocomplete/TerceroAutocompleteFormItem';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';

const fillT = (template, variables) => {
  return template.replace(/\${(.*?)}/g, (match, variable) => variables[variable]);
};

export default function RespuestaFormularioForm(props) {
  const {item, secciones, preguntaKey, editar = true} = props;
  //console.log('%c⧭ RespuestaFormularioForm', 'color: #364cd9', {item});

  const {control, getValues, register, setValue} = useFormContext();

  // useWatch no se puede usar dentro de un foreach
  // const watchNPregunta = useWatch({ name: `${preguntaKey}.nPregunta`, control });
  // const watchTienePuntaje = useWatch({ name: `${preguntaKey}.tienePuntaje`, control });
  // const watchLink = useWatch({ name: `${preguntaKey}.link`, control });
  // const watchTieneComentario = useWatch({ name: `${preguntaKey}.tieneComentario`, control });
  // const watchTipoRespuesta: any = useWatch({ name: `${preguntaKey}.tipoRespuesta`, control });
  // const watchUnidadMedida = useWatch({ name: `${preguntaKey}.unidadMedida`, control });
  // const watchPuntaje = useWatch({ name: `${preguntaKey}.puntaje`, control });
  // const watchRespuestaCorrecta = useWatch({ name: `${preguntaKey}.respuestaCorrecta`, control });
  // const watchAlternativas = useWatch({ name: `${preguntaKey}.alternativas`, control });
  // const watchComentario = getValues(`${preguntaKey}.comentario`);//useWatch({ name: `${preguntaKey}.comentario`, control });
  // const watchRespuesta = useWatch({ name: `${preguntaKey}.respuesta`, control });
  // const watchCheckbox = useWatch({ name: `${preguntaKey}.checkbox`, control });
  // const watchTextField = useWatch({ name: `${preguntaKey}.textField`, control });
  // const watchNumberField = useWatch({ name: `${preguntaKey}.numberField`, control });
  // const watchAmmount = useWatch({ name: `${preguntaKey}.ammount`, control });
  // const watchMultipleChoice = useWatch({ name: `${preguntaKey}.multipleChoice`, control });
  // const watchInspectionCheck = useWatch({ name: `${preguntaKey}.inspectionCheck`, control });
  // const watchYesNo = useWatch({ name: `${preguntaKey}.yesNo`, control });
  // const watchFiles = useWatch({ name: `${preguntaKey}.files`, control });
  // const watchImages = useWatch({ name: `${preguntaKey}.images`, control });
  // const watchSignature = useWatch({ name: `${preguntaKey}.signature`, control });
  // const watchDate = useWatch({ name: `${preguntaKey}.date`, control });
  // const watchTime = useWatch({ name: `${preguntaKey}.time`, control });
  // const watchLocation = useWatch({ name: `${preguntaKey}.location`, control });
  // const watchPerfilEmpleado = useWatch({ name: `${preguntaKey}.perfilEmpleado`, control });
  // const watchMeterReading = useWatch({ name: `${preguntaKey}.meterReading`, control });

  // const watchFields = {
  //   // nPregunta: watchNPregunta,
  //   // tienePuntaje: watchTienePuntaje,
  //   // link: watchLink,
  //   // tieneComentario: watchTieneComentario,
  //   // tipoRespuesta: watchTipoRespuesta,
  //   // unidadMedida: watchUnidadMedida,
  //   // puntaje: watchPuntaje,
  //   // respuestaCorrecta: watchRespuestaCorrecta,
  //   // alternativas: watchAlternativas,
  //   comentario: watchComentario,
  //   // respuesta: watchRespuesta,
  //   checkbox: watchCheckbox,
  //   textField: watchTextField,
  //   numberField: watchNumberField,
  //   ammount: watchAmmount,
  //   multipleChoice: watchMultipleChoice,
  //   inspectionCheck: watchInspectionCheck,
  //   yesNo: watchYesNo,
  //   files: watchFiles,
  //   images: watchImages,
  //   signature: watchSignature,
  //   date: watchDate,
  //   time: watchTime,
  //   location: watchLocation,
  //   perfilEmpleado: watchPerfilEmpleado,
  //   meterReading: watchMeterReading
  // };
  if (item.allowOthersOption) {
    if (item.alternativasCheckbox && item.alternativasCheckbox.slice(-1)[0]?.id !== 'others')
      item.alternativasCheckbox.push({id: 'others', label: 'Otros', value: false});
    if (item.alternativas && item.alternativas.slice(-1)[0]?.id !== 'others')
      item.alternativas.push({label: 'Otros', id: 'others'});
  }
  const preguntaFields = {
    respuestaCorrecta: {default: null},
    alternativas: {default: null},
    alternativasCheckbox: {default: null},
    respuesta: {default: null},
    checkbox: {default: null},
    multiCheckbox: {
      default: item.multiCheckbox
        ? item.multiCheckbox.map((a, i) => ({
            ...a,
            id: item.alternativasCheckbox?.[i]?.id,
            label: item.alternativasCheckbox?.[i]?.label,
          }))
        : item.alternativasCheckbox?.map((a) => {
            return {id: a.id, label: a.label, value: false};
          }),
    },
    textField: {default: null},
    numberField: {default: null},
    ammount: {default: null},
    multipleChoice: {default: null},
    inspectionCheck: {default: 'Aprobado'},
    yesNo: {default: null},
    files: {default: null},
    images: {default: null},
    signature: {default: null},
    date: {default: null},
    meterReading: {default: null},
    time: {default: null},
    location: {default: null},
    perfilEmpleado: {default: null},
    thirdParty: {default: null},
    asset: {default: null},
  };
  useEffect(() => {
    register(`${preguntaKey}.comentario`);
    Object.keys(preguntaFields).forEach((field) => {
      if ([item.tipoRespuesta].includes(field)) {
        register(`${preguntaKey}.${field}`, {required: item.isRequired});

        if ([field, item.tipoRespuesta].includes('multiCheckbox')) {
          //console.log('%c⧭ value: ' + preguntaKey + '.' + field, 'color: #99614d', {default: preguntaFields[field].default});
          setValue(`${preguntaKey}.${field}`, preguntaFields[field].default);
        } else {
          setValue(`${preguntaKey}.${field}`, item[field] ?? preguntaFields[field].default);
        }
      }
    });
  }, []);
  //console.log('%c⧭ preguntaKey', 'color: #f27999', preguntaKey);
  const watchMultiCheckbox = useWatch({
    name: `${preguntaKey}.multiCheckbox`,
    control,
    defaultValue: preguntaFields['multiCheckbox'].default,
  }); //, defaultValue: preguntaFields['multiCheckbox'].default });
  //const valuesMultiCheckbox = getValues(`${preguntaKey}.multiCheckbox`);
  //if ([item.tipoRespuesta].includes('multiCheckbox')) console.log('%c⧭ respuesta', 'color: #99614d', {valuesMultiCheckbox, watchMultiCheckbox, item});

  const [loadingReadImage, setLoadingReadImage] = React.useState(false);

  const onAnswerModified = (value) => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const coords = pos.coords;

      Geocode.fromLatLng(coords.latitude, coords.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;

          setValue(`${preguntaKey}.geolocation`, {
            latitud: coords.latitude,
            longitud: coords.longitude,
            address: address,
          });

          setValue('ultimaUbicacion', {
            latitud: coords.latitude,
            longitud: coords.longitude,
            address: address,
          });
        },
        (error) => {
          console.error(error);
        },
      );
    });
  };

  const decodeImage = (file): any => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        Quagga.decodeSingle(
          {
            decoder: {
              readers: [
                'code_128_reader',
                {
                  format: 'ean_reader',
                  config: {
                    supplements: ['ean_5_reader', 'ean_2_reader'],
                  },
                },
                'ean_8_reader',
                'code_39_reader',
                'code_39_vin_reader',
                'codabar_reader',
                'upc_reader',
                'upc_e_reader',
                'i2of5_reader',
                '2of5_reader',
                'code_93_reader',
              ], // Change this according to the type of barcode you want to read
            },
            locate: true,
            // @ts-ignore
            src: reader.result,
            numOfWorkers: 0, // Needs to be 0 when used on the client side
            inputStream: {
              size: 800, // restrict input-size to be 800px in width (long-side)
            },
          },
          (result) => {
            //console.log('%c⧭ result', 'color: #994d75', result);
            if (result && result.codeResult) {
              console.log('result', result.codeResult.code);
              resolve(result.codeResult.code);
            } else {
              console.log('not detected');
              resolve(null);
            }
          },
        );
      };
      reader.readAsDataURL(file);
    });
  };

  const readBarcode = async (image, name) => {
    const barcode = await decodeImage(image);
    if (!barcode) {
      Message.error('No se ha detectado un código de barras en la imagen');
    }
    console.log('%c⧭ barcode result', 'color: #00ff88', barcode);
    setValue(name, barcode);
  };

  const barcodeValue = useWatch({name: `${preguntaKey}.barcode`, control});
  const path = `[${item.pregunta?.label}]`;
  const requiredString = item.isRequired ? fillT(i18n('validation.string.required'), {path}) : undefined;
  return (
    secciones && (
      <Grid container direction="column">
        <Grid item pb={2}>
          <Typography variant="h4">
            {item.nPregunta + ') '} {item.pregunta?.titulo || item.pregunta?.label}{' '}
            {item.puntaje ? ' (' + item.puntaje + ' Puntos)' : null}
          </Typography>
          {/*<PreguntaViewPage id={item.pregunta?.id} image={true} />*/}
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            sx={{
              pl: item.nPregunta > 9 ? 3.5 : 2.5,
            }}>
            {(item.pregunta?.foto?.length > 0 || item.pregunta?.photo?.length > 0) && (
              <Grid item>
                <ImagesViewItem value={item.pregunta.foto || item.pregunta.photo} />
              </Grid>
            )}

            {item.pregunta?.descripcion && (
              <Grid item pb={1}>
                <Typography
                  component="pre"
                  variant="subtitle2"
                  sx={{
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                    fontFamily: 'inherit',
                    whiteSpace: 'pre-wrap',
                  }}>
                  {item.pregunta?.descripcion}
                </Typography>
              </Grid>
            )}
            {item.link && item.link !== 'Si' && (
              <Grid item pb={1}>
                <Link
                  sx={{textDecoration: 'none'}}
                  target="_blank"
                  href={item.link}
                  component={(props) => <Button {...props} variant="outlined" />}>
                  Abrir Enlace Adjunto
                </Link>
              </Grid>
            )}

            <Grid item pt={1}>
              <Typography variant="caption">Respuesta</Typography>
            </Grid>
            <Grid item>
              {item.tipoRespuesta === 'checkbox' && (
                <Box
                  sx={{
                    mt: -1,
                    ml: -1,
                  }}>
                  <CheckboxFormItem
                    onChange={onAnswerModified}
                    name={`${preguntaKey}.checkbox`}
                    disabled={!editar}
                    size="large"
                    required={requiredString}
                  />
                </Box>
              )}
              {item.tipoRespuesta === 'multiCheckbox' && watchMultiCheckbox && item.alternativasCheckbox && (
                <MultiCheckboxFormItem
                  name={`${preguntaKey}.multiCheckbox`}
                  nameOthers={`${preguntaKey}.multiCheckboxOthers`}
                  allowOthersOption={item.allowOthersOption}
                  onlyOneOption={item.onlyOneOption}
                  //onChange={value => setValue(`${preguntaKey}.multiCheckbox`, value)}
                  defaultValue={
                    watchMultiCheckbox ||
                    item.alternativasCheckbox.map((a) => {
                      return {id: a.id, label: a.label, value: false};
                    })
                  }
                  onChange={onAnswerModified}
                  disabled={!editar}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'multipleChoice'
                ? item.alternativas &&
                  item.alternativas.length > 0 && (
                    <SelectFormItem
                      name={`${preguntaKey}.multipleChoice`}
                      nameOthers={`${preguntaKey}.multipleChoiceOthers`}
                      allowOthersOption={item.allowOthersOption}
                      disabled={!editar}
                      label={!editar ? '' : 'Seleccione Opción'}
                      options={item.alternativas ? item.alternativas.map((item) => ({value: item.id, label: item.label})) : []}
                      required={requiredString}
                      onChange={onAnswerModified}
                    />
                  )
                : item.alternativas?.filter((alt) => item.respuesta === alt.id && item.respuestaCorrecta?.id === item.respuesta)
                    .length > 0
                ? item.alternativas
                    ?.filter((alt) => item.respuesta === alt.id && item.respuestaCorrecta?.id === item.respuesta)
                    .map((res) => (
                      <h2>
                        <Check style={{color: 'green', marginRight: 10}} /> {res.label}{' '}
                      </h2>
                    ))
                : item.alternativas
                    ?.filter((alt) => item.respuesta === alt.id)
                    .map((res) => (
                      <h2>
                        <Close style={{color: 'red', marginRight: 10}} /> {res.label}
                      </h2>
                    ))}

              {item.tipoRespuesta === 'textField' &&
                ((!loadingReadImage && (
                  <TextAreaFormItem
                    disabled={!editar}
                    label={!editar ? '' : 'Ingrese Respuesta'}
                    name={`${preguntaKey}.textField`}
                    onChange={onAnswerModified}
                    required={requiredString}
                  />
                )) ||
                  (loadingReadImage && (
                    <Box>
                      <Typography variant="caption">Leyendo imagen...</Typography>
                      <Spinner />
                    </Box>
                  )))}

              {item.tipoRespuesta === 'numberField' && (
                <InputNumberFormItem
                  disabled={!editar}
                  label={!editar ? '' : 'Ingrese Respuesta'}
                  name={`${preguntaKey}.numberField`}
                  onChange={onAnswerModified}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'ammount' && (
                <InputNumberFormItem
                  onChange={onAnswerModified}
                  disabled={!editar}
                  label={!editar ? '' : 'Ingrese Monto'}
                  name={`${preguntaKey}.ammount`}
                  required={requiredString}
                />
              )}

              {/* TODO: Validar rut */}
              {item.tipoRespuesta === 'registrationNumber' && (
                <InputFormItem
                  onChange={onAnswerModified}
                  disabled={!editar}
                  label={!editar ? '' : 'Ingrese RUT'}
                  name={`${preguntaKey}.registrationNumber`}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'inspectionCheck' && (
                <InputMultiSelectorFormItem
                  onChange={onAnswerModified}
                  options={[
                    {
                      value: 'Aprobado',
                      label: 'Aprobado',
                      color: 'green',
                    },
                    {
                      value: 'Observado',
                      label: 'Observado',
                      color: 'orange',
                    },
                    {
                      value: 'Rechazado',
                      label: 'Rechazado',
                      color: 'red',
                    },
                  ]}
                  disabled={!editar}
                  //label={!editar ? 'Respuesta' : 'Ingrese Estado'}
                  name={`${preguntaKey}.inspectionCheck`}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'yesNo' && (
                <SelectFormItem
                  onChange={onAnswerModified}
                  disabled={!editar}
                  name={`${preguntaKey}.yesNo`}
                  defaultValue={item.yesNo}
                  label={!editar ? '' : 'Ingrese Alternativa'}
                  options={[
                    {
                      value: 'Si',
                      label: 'Si',
                    },
                    {
                      value: 'No',
                      label: 'No',
                    },
                    {
                      value: 'N/A',
                      label: 'N/A',
                    },
                  ]}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'barcode' && (
                <Box mt={'2px'}>
                  {editar && (
                    <ImagesFormItem
                      onImageUpload={(imgFile) => readBarcode(imgFile, `${preguntaKey}.barcode`)}
                      name={`${preguntaKey}.barcodeImage`}
                      label={'Imagen de Código de Barra (Experimental)'}
                      required={requiredString}
                      storage={Storage.values.tareaFotosEnviadas}
                      max={1}
                    />
                  )}
                  <Box display="flex" flexDirection="row" alignItems="flex-end" gap={2}>
                    <Box sx={{width: 'fit-content'}}>
                      <InputFormItem
                        onChange={onAnswerModified}
                        name={`${preguntaKey}.barcode`}
                        disabled={true}
                        label={'Código de Barra'}
                        required={requiredString}
                      />
                    </Box>
                    {barcodeValue && (
                      <Box sx={{mb: 1, width: '26px', height: '26px'}}>
                        <Check style={{color: 'green'}} />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {item.tipoRespuesta === 'files' && (
                <Box mt={'2px'}>
                  {!item.imagesOnly && (
                    <FilesFormItem
                      onChange={onAnswerModified}
                      name={`${preguntaKey}.files`}
                      required={requiredString}
                      label={!editar ? 'Documentos Ingresados' : 'Añadir Documentos'}
                      storage={Storage.values.tareaDocumentosEnviados}
                      max={undefined}
                      disabled={!editar}
                      formats={undefined}
                    />
                  )}
                  <ImagesFormItem
                    onChange={onAnswerModified}
                    name={`${preguntaKey}.images`}
                    label={!editar ? 'Fotos Ingresadas' : 'Añadir Fotos'}
                    required={requiredString}
                    storage={Storage.values.tareaFotosEnviadas}
                    disabled={!editar}
                    AIContent
                    //max={3}
                  />
                </Box>
              )}

              {item.tipoRespuesta === 'signature' && (
                <InputSignatureFormItem
                  onChange={onAnswerModified}
                  name={`${preguntaKey}.signature`}
                  label={!editar ? '' : 'Ingresar Firma'}
                  disabled={!editar}
                  width={window.innerWidth > 768 ? 500 : 280}
                  height={300}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'day' && (
                <SelectFormItem
                  onChange={onAnswerModified}
                  name={`${preguntaKey}.day`}
                  disabled={!editar}
                  label={!editar ? '' : 'Seleccione un día'}
                  options={[
                    {
                      value: 'monday',
                      label: 'Lunes',
                    },
                    {
                      value: 'tuesday',
                      label: 'Martes',
                    },
                    {
                      value: 'wednesday',
                      label: 'Miercoles',
                    },
                    {
                      value: 'thursday',
                      label: 'Jueves',
                    },
                    {
                      value: 'friday',
                      label: 'Viernes',
                    },
                    {
                      value: 'saturday',
                      label: 'Sabado',
                    },
                    {
                      value: 'sunday',
                      label: 'Domingo',
                    },
                  ]}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'month' && (
                <SelectFormItem
                  onChange={onAnswerModified}
                  name={`${preguntaKey}.month`}
                  disabled={!editar}
                  label={!editar ? '' : 'Seleccione un mes'}
                  options={[
                    {
                      value: 'january',
                      label: 'Enero',
                    },
                    {
                      value: 'february',
                      label: 'Febrero',
                    },
                    {
                      value: 'march',
                      label: 'Marzo',
                    },
                    {
                      value: 'april',
                      label: 'Abril',
                    },
                    {
                      value: 'may',
                      label: 'Mayo',
                    },
                    {
                      value: 'june',
                      label: 'Junio',
                    },
                    {
                      value: 'july',
                      label: 'Julio',
                    },
                    {
                      value: 'august',
                      label: 'Agosto',
                    },
                    {
                      value: 'september',
                      label: 'Septiembre',
                    },
                    {
                      value: 'october',
                      label: 'Octubre',
                    },
                    {
                      value: 'november',
                      label: 'Noviembre',
                    },
                    {
                      value: 'december',
                      label: 'Diciembre',
                    },
                  ]}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'date' && (
                <DatePickerFormItem
                  onChange={onAnswerModified}
                  minDate={item.allowDateBefore ? undefined : moment().toISOString()}
                  disabled={!editar}
                  name={`${preguntaKey}.date`}
                  label={!editar ? '' : 'Seleccione Fecha'}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'time' && (
                <DatePickerFormItem
                  onChange={onAnswerModified}
                  disabled={!editar}
                  name={`${preguntaKey}.time`}
                  label={!editar ? '' : 'Seleccione Hora'}
                  required={requiredString}
                  {...{timeOnly: true}}
                />
              )}

              {item.tipoRespuesta === 'location' && (
                <UbicacionAutocompleteFormItem
                  onChange={onAnswerModified}
                  filter={{restrictByLocation: item.restrictByLocation || 0}}
                  disabled={!editar}
                  defaultValue={item.location}
                  name={`${preguntaKey}.location`}
                  size={'large'}
                  label={!editar ? '' : 'Seleccione Ubicación'}
                  //onChange={(value) => setValue(`${name}.location`, value)}
                  required={requiredString}
                  showCreate={false}
                  mode={item.isMultiple ? 'multiple' : undefined}
                  menuPortalTarget={document.body}
                />
              )}

              {item.tipoRespuesta === 'perfilEmpleado' && (
                <PerfilEmpleadoAutocompleteFormItem
                  onChange={onAnswerModified}
                  filter={{restrictByLocation: item.restrictByLocation || undefined}}
                  disabled={!editar}
                  defaultValue={item.perfilEmpleado}
                  name={`${preguntaKey}.perfilEmpleado`}
                  size={'large'}
                  label={!editar ? '' : 'Seleccione Colaborador'}
                  //onChange={(value) => setValue(`${name}.perfilEmpleado`, value)}
                  required={requiredString}
                  showCreate={false}
                  mode={item.isMultiple ? 'multiple' : undefined}
                  menuPortalTarget={document.body}
                />
              )}

              {item.tipoRespuesta === 'asset' && (
                <ActivoAutocompleteFormItem
                  onChange={onAnswerModified}
                  filter={{grupo: 'Equipo', restrictByLocation: item.restrictByLocation || 0}}
                  disabled={!editar}
                  defaultValue={item.asset}
                  name={`${preguntaKey}.asset`}
                  size={'large'}
                  label={!editar ? '' : 'Seleccione Activo'}
                  //onChange={(value) => setValue(`${name}.perfilEmpleado`, value)}
                  required={requiredString}
                  showCreate={false}
                  mode={item.isMultiple ? 'multiple' : undefined}
                  menuPortalTarget={document.body}
                />
              )}

              {item.tipoRespuesta === 'thirdParty' && (
                <TerceroAutocompleteFormItem
                  onChange={onAnswerModified}
                  filter={{restrictByLocation: item.restrictByLocation || 0}}
                  disabled={!editar}
                  defaultValue={item.thirdParty}
                  name={`${preguntaKey}.thirdParty`}
                  size={'large'}
                  label={!editar ? '' : 'Seleccione Tercero'}
                  //onChange={(value) => setValue(`${name}.perfilEmpleado`, value)}
                  required={requiredString}
                  showCreate={false}
                  mode={item.isMultiple ? 'multiple' : undefined}
                  menuPortalTarget={document.body}
                />
              )}

              {item.tipoRespuesta === 'meterReading' && (
                <InputNumberFormItem
                  onChange={onAnswerModified}
                  disabled={!editar}
                  label={!editar ? '' : 'Ingrese Medición'}
                  name={`${preguntaKey}.meterReading`}
                  required={requiredString}
                />
              )}

              {item.tipoRespuesta === 'year' && (
                <InputNumberFormItem
                  onChange={(value) => {
                    if (value > 9999) {
                      let croppedValue = value.toString().substring(0, 4);
                      setValue(`${preguntaKey}.year`, Number(croppedValue));
                    }
                    if (value < 0) {
                      setValue(`${preguntaKey}.year`, 0);
                    }
                    onAnswerModified(value);
                  }}
                  min={0}
                  disabled={!editar}
                  label={!editar ? '' : 'Ingrese Año'}
                  name={`${preguntaKey}.year`}
                  disableFormat={true}
                  required={requiredString}
                />
              )}
            </Grid>

            {item.haveCriticality && (
              <Grid item pb={1}>
                <Typography variant="caption">{!editar ? 'Nivel de Criticidad' : 'Ingrese Criticidad'}</Typography>
                <InputMultiSelectorFormItem
                  onChange={onAnswerModified}
                  options={[
                    {
                      value: 'Baja',
                      label: 'Baja',
                      color: 'green',
                    },
                    {
                      value: 'Media',
                      label: 'Media',
                      color: 'orange',
                    },
                    {
                      value: 'Alta',
                      label: 'Alta',
                      color: 'red',
                    },
                  ]}
                  disabled={!editar}
                  name={`${preguntaKey}.criticality`}
                  required={requiredString}
                />
              </Grid>
            )}

            {(item.tieneComentario || item.comentario) &&
              (editar ? (
                <Grid item sx={{pb: 1}}>
                  <Typography variant="caption">Comentarios Adicionales</Typography>
                  <TextAreaFormItem
                    onChange={onAnswerModified}
                    name={`${preguntaKey}.comentario`}
                    defaultValue={item.comentario}
                    placeholder="Puedes detallar más en este campo..."
                  />
                </Grid>
              ) : (
                item.comentario && (
                  <Grid item sx={{pb: 1}}>
                    <Typography variant="caption">Comentarios Adicionales</Typography>
                    <Box sx={{p: 1, border: '1px solid lightgray', borderRadius: '5px'}}>
                      <Typography variant="subtitle1">{item.comentario}</Typography>
                    </Box>
                  </Grid>
                )
              ))}

            {((editar && item.havePhotos) || item.additionalPhotos?.length > 0) && (
              <Grid item>
                <Typography variant="caption">Fotos Adicionales</Typography>
                <ImagesFormItem
                  onChange={onAnswerModified}
                  name={`${preguntaKey}.additionalPhotos`}
                  label={!editar ? '' : 'Añadir Fotos'}
                  required={false}
                  storage={Storage.values.tareaFotosEnviadas}
                  disabled={!editar || loadingReadImage}
                  AIContent
                  //max={3}
                />
              </Grid>
            )}

            {((editar && item.haveFiles) || item.additionalFiles?.length > 0) && (
              <Grid item>
                <Typography variant="caption">Archivos Adicionales</Typography>
                <FilesFormItem
                  onChange={onAnswerModified}
                  name={`${preguntaKey}.additionalFiles`}
                  required={false}
                  label={!editar ? '' : 'Añadir Documentos'}
                  storage={Storage.values.tareaDocumentosEnviados}
                  max={undefined}
                  disabled={!editar}
                  formats={undefined}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
