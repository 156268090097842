import axios from 'axios';
import moment from 'moment-timezone';
import PQueue from 'p-queue';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Resizer from 'react-image-file-resizer';
import {i18n} from 'src/i18n';
import SettingsService from 'src/modules/settings/settingsService';
import TareaService from 'src/modules/tarea/tareaService';
import TenantService from 'src/modules/tenant/tenantService';
import UserService from 'src/modules/user/userService';

const textOverImage = (imgSrc, text) => {
  return new Promise((resolve, reject) => {
    // Crear un elemento de imagen en el DOM
    const img = new Image();
    img.src = imgSrc;

    img.onload = function () {
      // Crear un lienzo (canvas) en memoria
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      // Obtener contexto 2D del canvas
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.log('PDF canvas error', 'No access to 2d context');
        return;
      }
      ctx.drawImage(img, 0, 0); // Dibujar la imagen original en el canvas

      // Fecha que quieres sobreponer (puedes personalizar el formato)
      const textOptions: any = {
        text,
        fontSize: '26px',
        fontColor: 'white',
        fontFamily: 'Arial',
        textAlign: 'left',
        textBaseline: 'bottom',
      };

      // Sobreponer texto en la imagen en el canvas
      ctx.fillStyle = textOptions.fontColor;
      ctx.font = textOptions.fontSize + ' ' + textOptions.fontFamily;
      ctx.textAlign = textOptions.textAlign;
      ctx.textBaseline = textOptions.textBaseline;
      ctx.fillText(textOptions.text, canvas.width - 222, canvas.height - 15); // Posiciona el texto centrado en la parte inferior

      // Convertir el canvas a una imagen base64
      const modifiedImageBase64 = canvas.toDataURL('image/jpeg'); // Puedes cambiar 'image/jpeg' según el tipo de imagen que necesites

      // Resolver la promesa con la imagen modificada
      resolve(modifiedImageBase64);
    };

    img.onerror = function (err) {
      // Rechazar la promesa en caso de error
      reject(err);
    };
  });
};

const resizeFile = (file) => {
  console.log('%c⧭', 'color: #86bf60', 'RESIZING!');
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
    );
  });
};
const urlToBase64 = async (url, {isBlumarPT = false, imgText}: any = {}) => {
  try {
    const response = await axios.get(url + '?v=' + (Math.floor(Math.random() * 10) + 1), {responseType: 'blob'});
    const resizedImg = await resizeFile(response.data);
    if (isBlumarPT) {
      const imgWithDate = await textOverImage(resizedImg, imgText);
      return imgWithDate;
    }
    return resizedImg;
  } catch (error) {
    console.log('urlToBase64 error', {error});
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=';
  }
};
const getTextMaxWidth = (content) => {
  const pdf = pdfMake.createPdf({content});
  pdf.getStream({});
  return {
    ...pdf.docDefinition.content[0],
    width: pdf.docDefinition.content[0]._maxWidth,
  };
};
const tareaPdf = {
  exportDataAsPdf: async (tarea, {tenant = null, settings = null, open = true}: any = {}) => {
    const text = (text, styles = {}) => {
      return {text: text, fontSize: 9, margin: [0, textMargin, 0, textMargin + 1], alignment: 'left', ...styles};
    };
    const imgs = async (images, styles = {}) => {
      //console.log('%c⧭ imgs', 'color: #408059', images);
      const imgs: any = [];
      const atLeastOneAIContent = images.some((img) => img?.AIContent);
      const queue = new PQueue({concurrency: 1});
      await queue.addAll(
        images.map((img, index) => async () => {
          const imgText = img.file?.datetime ? moment(img.file?.datetime).format('DD/MM/YYYY HH:mm') : '';
          if (isConsolidated) {
            imgs.push({
              stack: [
                {text: img.titulo},
                {image: await urlToBase64(img.publicUrl, {isBlumarPT: true, imgText}), fit: imgFit, ...styles},
              ],
            });
          } else {
            imgs.push({image: await urlToBase64(img.publicUrl, {isBlumarPT: true, imgText}), fit: imgFit, ...styles});
          }
          const AIContent = img?.AIContent;
          if (atLeastOneAIContent) {
            let imageDescriptions: any = [[{}, {}]];
            if (AIContent) {
              imageDescriptions = Object.keys(AIContent).map((key) => {
                let content: any = AIContent[key];
                if (Array.isArray(content)) {
                  content = {
                    stack: content.map((item) => {
                      return {text: item, fontSize: 8, alignment: 'left'};
                    }),
                    margin: [1, 1],
                  };
                } else {
                  content = {text: content, fontSize: 8, margin: [1, 1], alignment: 'left'};
                }
                return [
                  {
                    text: key,
                    fontSize: 8,
                    bold: true,
                    margin: [1, 1],
                    alignment: 'left',
                  },
                  content,
                ];
              });
              imgs.push(
                infoTable(
                  {
                    widths: ['auto', '*'],
                    body: imageDescriptions,
                  },
                  {margin: [0, isConsolidated ? 12 : 0, 0, 0]},
                ),
              );
            } else {
              const imgsLength = imgs.length;
              const nextSlotIsPair = imgsLength % 2 === 0;
              //const lastImg = index === 0 ? null : images[index - 1];
              const nextImg = index === images.length - 1 ? null : images[index + 1];
              if (nextImg?.AIContent && !nextSlotIsPair) {
                imgs.push({text: '', width: 250});
              }
            }
          }
        }),
      );
      return imgs;
    };
    const breakArray = (array, size) => {
      const result: any = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };
    const imgsGrid = async (images, styles = {}, size = imgsPerRow, imgStyles = {}) => {
      const booleanFilteredImages = images?.filter(Boolean) || [];
      return breakArray(await imgs(booleanFilteredImages, imgStyles), size).map((columns) => ({
        columns,
        ...styles,
        unbreakable: true,
      }));
    };

    tenant = tenant || (await TenantService.findCurrent());
    settings = settings || (await SettingsService.find());
    //console.log('%c⧭ exportDataAsPdf settings tenant', 'color: #731d6d', settings, tenant);
    const tenantName = tenant.name ?? '';
    let logo: any = null;
    if (settings?.logosPdf?.length) {
      logo = await urlToBase64(settings.logosPdf[0].publicUrl);
    } else if (settings?.logos?.length) {
      logo = await urlToBase64(settings.logos[0].publicUrl);
    }

    const isServiOctava = tenantName === 'SERVIOCTAVA';
    const imgsPerRowConsolidated = !isServiOctava ? 2 : 4;
    const imgFitConsolidated = !isServiOctava ? [258, 318] : [120, 318];

    const imgsPerRow = !isServiOctava ? 2 : 4;
    const imgFit = !isServiOctava ? [250, 300] : [120, 300];
    const columnGap = !isServiOctava ? undefined : 2;

    const isBlumarPT = tenant.id === '663c2f9ad6da031a0a9389f3';

    //console.log('%c⧭ exportDataAsPdf tarea', 'color: #007300', tarea);
    const minMargin = 20;
    const {
      estado,
      descripcion,
      titulo,
      subtitulo,
      categoria,
      modeloTarea,
      planificacion,
      fechaLimite,
      inicio,
      comienzo,
      fin,
      fechaProgramacion,
      tipoVisualizacion,
      tipoMantenimiento, // Programada
      listaProcedimiento,
      respuestaInterfaz,
      pasoActual,
      firmas,
      documentosEnviados,
      fotosEnviadas,
      dirigidoA,
      ejecutor,
      puesto,
      comentarioEnviado,
      activo,
      horometro,
      kilometraje,
      tituloTarea,
      headers,
      rows,
      summary,
      images,
      isConsolidated,
    } = tarea;
    const imgText = comienzo ? moment(comienzo).format('DD/MM/YYYY') : '';
    const nombre = planificacion?.nombre;
    firmas &&
      (await Promise.all(
        firmas.map(async (f) => {
          if (!f.firma || f.responsable?.id) return;
          const responsable = await UserService.find(f.responsable);
          f.responsable = responsable;
        }),
      ));
    //console.log('%c⧭ firmas', 'color: #e57373', firmas);

    const tituloPdf = `${titulo} ${tituloTarea || modeloTarea?.titulo || descripcion}`; // (${estado})`;
    const descripcionTarea = modeloTarea?.descripcion;
    const fechaIni = comienzo ? moment(comienzo).format('DD-MM-YYYY HH:mm') : null;
    //fechaProgramacion ? moment(fechaProgramacion).format('DD MMMM YYYY') : null;
    const fechaVencimiento = fechaLimite ? moment(fechaLimite).format('DD MMMM YYYY') : null;
    const fechaFin = fin ? moment(fin).format('DD-MM-YYYY HH:mm') : null;
    const elDirigido = ejecutor ? ejecutor.fullName || ejecutor.email : puesto?.nombre;

    const content: any = [];
    const textMargin = 0;
    const replyMargin = [10, textMargin + 1, 0, 0];
    const tituloTable = {
      layout: 'headerTable',
      width: '*',
      alignment: 'center',
      table: {
        widths: ['auto', '*', 'auto'],
        body: [
          [
            logo
              ? {minWidth: 80, maxWidth: 100, image: logo, maxHeight: 40, rowSpan: 2, margin: [0, 5]}
              : {text: tenantName, rowSpan: 2, margin: [0, 5]},
            {
              text: [{text: tituloPdf, fontSize: 13, bold: true}, subtitulo ? {text: '\n' + subtitulo, fontSize: 10} : {}],
              margin: [8, 5],
              rowSpan: 2,
            },
            fechaIni
              ? {
                  text: [
                    {text: isConsolidated ? 'Desde' : 'Desde', bold: true, noWrap: true, fontSize: 9},
                    {text: '\n'},
                    {text: fechaIni || '', noWrap: true, fontSize: 10},
                  ],
                  margin: [1, 3],
                  alignment: 'left',
                }
              : {},
          ],
          [
            {},
            {},
            fechaFin
              ? {
                  text: [
                    {text: isConsolidated ? 'Hasta' : 'Hasta', bold: true, noWrap: true, fontSize: 9},
                    {text: '\n'},
                    {text: fechaFin || '', noWrap: true, fontSize: 10},
                  ],
                  margin: [1, 3],
                  alignment: 'left',
                }
              : {},
            //fechaVencimiento ? { text: [{text: 'Vencimiento: ', fontSize: 10, bold: true}, fechaVencimiento], alignment: 'right' } : ''
          ],
        ],
      },
    };
    //content.push(tituloTable);
    //content.push(text(tituloPdf, { alignment: 'center', fontSize: 13, bold: true }));
    //if (subtitulo) content.push(text(subtitulo, { alignment: 'center', fontSize: 9 }));
    //content.push({text:'', margin: [0, 16]});
    // content.push({
    //   columns: [
    //     { text: nombre? 'Planificación: ' + nombre: 'No planificado/No programado', alignment: 'left' },
    //     { text: activo ? `Activo: ${activo.nombre}` : '', alignment: 'right' },
    //   ], margin: [0, 5]
    // });
    //const codigo = activo?.codigo || activo?.patente; // en pesqueras pusieron la patente en el nombre
    const infoTable = (table, extra: any = {}) => ({
      layout: 'table',
      width: '*',
      alignment: 'center',
      table,
      ...extra,
    });

    if (activo) {
      //const activoLabel = `${activo?.nombre}${activo?.codigo ? ` | ${activo.codigo}` : ''}`;
      // content.push({
      //   text: [activo ? {text: `Activo: `, bold: true} : '', activo ? {text: activoLabel, bold: false} : ''],
      //   margin: [0, 0, 0, 0],
      //   alignment: 'center',
      //   fontSize: 13,
      // });
      const activoTableBody: any = [];
      if (activo?.nombre && activo?.nombre !== activo?.codigo) {
        activoTableBody.push([
          {
            text: i18n('entities.activo.fields.nombre'),
            bold: true,
            fontSize: 10,
            alignment: 'left',
          },
          {text: activo.nombre, fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      if (activo?.codigo) {
        activoTableBody.push([
          {text: i18n('entities.activo.fields.codigo'), bold: true, fontSize: 10, alignment: 'left'},
          {text: activo.codigo, fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      if (activo?.patente) {
        activoTableBody.push([
          {text: i18n('entities.activo.fields.patente'), bold: true, fontSize: 10, alignment: 'left'},
          {text: activo.patente, fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      if (activo?.tipoActivo?.length > 0) {
        activoTableBody.push([
          {text: i18n('entities.activo.fields.tipoActivo'), bold: true, fontSize: 10, alignment: 'left'},
          {text: activo.tipoActivo.map((t) => t.descripcion).join(', '), fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      if (activo?.marca) {
        activoTableBody.push([
          {text: i18n('entities.activo.fields.marca'), bold: true, fontSize: 10, alignment: 'left'},
          {text: activo.marca, fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      if (activo?.modelo) {
        activoTableBody.push([
          {text: i18n('entities.activo.fields.modelo'), bold: true, fontSize: 10, alignment: 'left'},
          {text: activo.modelo, fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      if (activo?.numeroSerial) {
        activoTableBody.push([
          {text: i18n('entities.activo.fields.numeroSerial'), bold: true, fontSize: 10, alignment: 'left'},
          {text: activo.numeroSerial, fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      // if (activo?.localizacion) {
      //   activoTableBody.push([
      //     {text: i18n('entities.activo.fields.localizacion'), bold: true, fontSize: 10, alignment: 'left'},
      //     {text: activo.localizacion.label, fontSize: 10, alignment: 'left'},
      //     {},
      //   ]);
      // }
      if (activo?.locations) {
        activoTableBody.push([
          //i18n('common.locations')
          {text: 'Ubicación', bold: true, fontSize: 10, alignment: 'left'},
          {text: activo.locations.map((l) => l.label).join(', '), fontSize: 10, alignment: 'left'},
          {},
        ]);
      }
      const fotos = activo.fotos;
      const activoFotos = fotos?.length > 0 ? await imgsGrid(fotos, {margin: [0, 0, 0, 0]}, 1, {fit: [100, 100]}) : null;
      activoTableBody.forEach((row, index) => {
        const secondColumn = row[1];
        const thirdColumn = row[2];
        if (activoFotos?.length > 0) {
          if (index === 0) {
            thirdColumn.stack = activoFotos;
            thirdColumn.rowSpan = activoTableBody.length;
          }
        } else {
          secondColumn.colSpan = 2;
        }
      });
      const activoTable = infoTable(
        {
          headerRows: 1,
          widths: [80, '*', 'auto'],
          body: [
            [{text: 'Activo', bold: true, fontSize: 10, alignment: 'center', colSpan: 3, margin: [0, 1]}, {}, {}],
            ...activoTableBody,
          ],
        },
        {
          margin: [0, 10, 0, 20],
        },
      );
      content.push(activoTable);
    }
    const firmasConNota = firmas?.filter((f) => {
      return f.nota && f.firmada;
    });
    if (firmasConNota.length > 0) {
      console.log('%c⧭ firmasConNota', 'color: #006dcc', firmasConNota);
      const firmasConNotaTable = infoTable(
        {
          headerRows: 1,
          widths: ['auto', 'auto', '*'],
          body: [
            [{text: 'Notas', bold: true, fontSize: 10, alignment: 'center', colSpan: 3, margin: [0, 1]}, {}, {}],
            ...firmasConNota.map((f) => [
              {text: f.updatedAt ? moment(f.updatedAt).format('DD/MM/YYYY HH:mm') : '', fontSize: 10, alignment: 'left'},
              {text: f.responsable?.fullName || f.responsable?.email, fontSize: 10, alignment: 'left'},
              {text: f.nota, fontSize: 10, alignment: 'left'},
            ]),
          ],
        },
        {
          margin: [0, 10, 0, 20],
        },
      );
      content.push(firmasConNotaTable);
    }

    /* {tipoVisualizacion === 'Procedimiento' && <p>Tipo: Procedimiento</p>} */
    const infoBody: any = [];
    if (nombre) {
      infoBody.push([
        {text: 'Planificación', bold: true, fontSize: 10, alignment: 'left'},
        {text: nombre || 'No planificado', fontSize: 10, alignment: 'left'},
      ]);
    }
    if (!isServiOctava && categoria) {
      infoBody.push([
        {text: 'Categoría', bold: true, fontSize: 10, alignment: 'left'},
        {text: categoria.nombre, fontSize: 10, alignment: 'left'},
      ]);
    }
    //if (categoria) content.push(text(categoria.nombre));
    if (!isServiOctava && elDirigido) {
      infoBody.push([
        {text: 'Ejecutor', bold: true, fontSize: 10, alignment: 'left'},
        {text: elDirigido, fontSize: 10, alignment: 'left'},
      ]);
    }
    //content.push(text(`Realizado por: ${elDirigido}`));
    if (horometro)
      infoBody.push([
        {text: 'Horómetro', bold: true, fontSize: 10, alignment: 'left'},
        {text: horometro, fontSize: 10, alignment: 'left'},
      ]);
    if (kilometraje)
      infoBody.push([
        {text: 'Kilometraje', bold: true, fontSize: 10, alignment: 'left'},
        {text: kilometraje, fontSize: 10, alignment: 'left'},
      ]);
    //if (horometro) content.push(text('Horómetro: ' + horometro));
    if (descripcionTarea)
      infoBody.push([
        {text: 'Descripción', bold: true, fontSize: 10, alignment: 'left'},
        {text: descripcionTarea, fontSize: 10, alignment: 'left'},
      ]);
    //if (descripcionTarea) content.push(text('Descripción: ' + descripcionTarea));

    if (!isConsolidated && infoBody.length > 0) {
      content.push(
        infoTable(
          {
            widths: [80, '*'],
            body: infoBody,
          },
          {
            margin: [0, activo ? 0 : 10, 0, 8],
          },
        ),
      );
    }

    if (isConsolidated) {
      content.push({
        text: 'DATOS DE LA OPERACIÓN',
        bold: true,
        fontSize: 12,
        alignment: 'left',
        margin: [0, 10, 0, 4],
      });
      const summaryTable = {
        headerRows: 1,
        widths: ['auto', '*'],
        body: [
          ...summary
            .filter((s) => s.showInSummary)
            .map((s) => [
              {text: s.label, alignment: 'left'},
              {text: s.value || '', alignment: 'left'},
            ]),
        ],
      };
      content.push(
        infoTable(summaryTable, {
          margin: [0, 0, 0, 0],
        }),
      );
      if (headers && rows) {
        content.push({
          text: 'TABLA DE CUADRATURA',
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 10, 0, 4],
          pageBreak: 'before',
          pageOrientation: 'landscape',
        });
        const headersInTable = headers.filter((h) => h.showInTable);
        // split headers in groups of 10
        const headersGroups = breakArray(headersInTable, 8);
        //console.log('%c⧭ headersGroups', 'color: #ff0000', headersGroups);
        const rowsGrouped = rows.map((row) => {
          const rowsInTable = row.filter((cell) => cell.showInTable);
          return breakArray(rowsInTable, 8);
        });
        for (let i = 0; i < headersGroups.length; i++) {
          const headerGroup = headersGroups[i];
          //console.log('%c⧭ headerGroup', 'color: #ff0000', headerGroup);
          const table = {
            headerRows: 1,
            widths: headerGroup.map(() => 'auto'),
            heights: 20,
            body: [
              headerGroup.map((header) => ({text: header.label, fontSize: 10, alignment: 'left', bold: true})),
              ...rowsGrouped.map((row) =>
                row[i].map((cell, index) => {
                  return {
                    columns: [{text: cell.value || '', alignment: 'left'}],
                    minWidth: 20,
                  };
                }),
              ),
            ],
          };
          const pageBreak = i > 0 ? 'before' : undefined;
          const newInfoTable = infoTable(table, {
            margin: [0, i > 0 ? 20 : 0, 0, 0],
            pageBreak,
          });
          //console.log('i:', getTextMaxWidth([newInfoTable]));
          content.push(newInfoTable);
        }
        // const consolidatedTable = {
        //   headerRows: 2,
        //   //dontBreakRows: true,
        //   widths: [...headersInTable.map(() => 'auto')],
        //   body: [
        //     headersInTable.map((header) => ({text: header.label, fontSize: 10, alignment: 'left', bold: true})),
        //     ...rows.map((row) =>
        //       row
        //         .filter((cell) => cell.showInTable)
        //         .map((cell, index) => ({text: cell.value || '', alignment: 'left', bold: index === 0})),
        //     ),
        //   ],
        // };
        // console.log('%c⧭ consolidatedTable', 'color: #f2ceb6', consolidatedTable);
        // content.push(
        //   infoTable(consolidatedTable, {
        //     margin: [0, 0, 0, 0],
        //   }),
        // );
      }
      if (images?.length > 0) {
        content.push({
          text: 'FOTOS ADJUNTAS',
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 0, 0, 4],
          pageBreak: 'before',
          pageOrientation: 'portrait',
        });
        content.push(...(await imgsGrid(images, {margin: [0, 0, 0, 10]}, imgsPerRowConsolidated, {fit: imgFitConsolidated})));
      }
    }

    // armar una tabla con las tareas hijas de la tarea actual
    const tareaWithChildren = await TareaService.list({id: tarea.id, withChildren: true}, null, 1, null);

    if (tareaWithChildren?.rows?.length > 0) {
      const tareasHijas = tareaWithChildren.rows[0].children;
      if (tareasHijas && tareasHijas.length > 0) {
        const tareasHijasTable = infoTable(
          {
            headerRows: 2,
            widths: ['auto', 'auto', '*', 'auto', 'auto'],
            body: [
              [{text: 'Seguimiento', bold: true, fontSize: 10, alignment: 'center', colSpan: 5, margin: [0, 1]}, {}, {}, {}, {}],
              [
                {text: 'Tarea', bold: true, fontSize: 10},
                {text: 'Estado', bold: true, fontSize: 10},
                {text: 'Ejecutor', bold: true, fontSize: 10},
                {text: 'Desde', bold: true, fontSize: 10},
                {text: 'Hasta', bold: true, fontSize: 10},
              ],
              ...tareasHijas.map((tareaHija) => [
                {text: tareaHija.titulo, fontSize: 10, alignment: 'left'},
                {text: tareaHija.estado, fontSize: 10, alignment: 'left'},
                {text: tareaHija.ejecutor?.fullName || '', fontSize: 10, alignment: 'left'},
                {text: tareaHija.comienzo ? moment(tareaHija.comienzo).format('DD-MM-YYYY') : 'Pendiente', fontSize: 10},
                {text: tareaHija.fin ? moment(tareaHija.fin).format('DD-MM-YYYY') : 'Pendiente', fontSize: 10},
              ]),
            ],
          },
          {
            margin: [0, infoBody.length > 0 ? 12 : 20, 0, 8],
          },
        );
        content.push(tareasHijasTable);
      }
    }

    const queue = new PQueue({concurrency: 1});
    // let count = 0;
    // queue.on('completed', (result) => {
    //   console.log(`Completed item #${++count}.`, result);
    // });
    if (['Checklist', 'Pasos'].includes(tipoVisualizacion) && respuestaInterfaz && modeloTarea?.subTareasRelacionadas?.length) {
      const subTareasRelacionadas = modeloTarea.subTareasRelacionadas;
      const totalSubTareas = subTareasRelacionadas.length;
      const respuestaFilter = tipoVisualizacion === 'Checklist' ? (r, index) => r?.checkbox : (r, index) => index < pasoActual;
      const marginCheck = tipoVisualizacion === 'Checklist' ? [0, 0, 0, -5] : [0, 0, 0, -6];
      let subTareasCompletadas = respuestaInterfaz.filter(respuestaFilter).length;
      if (subTareasCompletadas > totalSubTareas) subTareasCompletadas = totalSubTareas;
      const porcentajeCompletado = (subTareasCompletadas * 100) / totalSubTareas;
      //completado
      const checkPasosBody: any = [];
      checkPasosBody.push([
        {
          text: `Completado: ${porcentajeCompletado.toFixed(1)}%`,
          bold: true,
          fontSize: 10,
          alignment: 'center',
          colSpan: 2,
        },
        {},
      ]);
      //content.push(text(`Completado: ${porcentajeCompletado.toFixed(1)}%`, {alignment: 'center', fontSize: 11, margin: [0, 18]}));
      //listado
      subTareasRelacionadas.forEach((subTarea, index) => {
        const r = respuestaInterfaz[index];
        if (!subTarea) return;
        checkPasosBody.push([
          {
            stack: [
              {
                canvas:
                  tipoVisualizacion === 'Checklist'
                    ? [
                        {
                          type: 'polyline',
                          lineWidth: 1,
                          closePath: true,
                          points: [
                            {x: 0, y: 0},
                            {x: 8, y: 0},
                            {x: 8, y: 8},
                            {x: 0, y: 8},
                          ],
                        },
                      ]
                    : [
                        {
                          type: 'ellipse',
                          x: 4,
                          y: 4,
                          color: 'black',
                          fillOpacity: 1,
                          r1: 5,
                          r2: 5,
                        },
                        {
                          type: 'ellipse',
                          x: 4,
                          y: 4,
                          color: 'white',
                          fillOpacity: 1,
                          r1: 4,
                          r2: 4,
                        },
                      ],
                margin: marginCheck,
              },
              {text: respuestaFilter(r, index) ? '√' : ' ', margin: [0, -5, 0, 0]},
            ],
            width: 8,
            margin: [20, 13, 20, 15],
          },
          {text: subTarea.nombre, fontSize: 11, margin: [40, 11, 20, 15], alignment: 'left'},
        ]);
      });
      content.push(
        infoTable(
          {
            widths: [80, '*'],
            body: checkPasosBody,
          },
          {margin: [0, 25, 0, 0]},
        ),
      );
    }
    if (tipoVisualizacion === 'Procedimiento' && listaProcedimiento) {
      const numberedList: any = [];
      const listMargins = [10, textMargin, 0, textMargin + 1];
      const streetLightMargins = [-15, 2, 0, 0];
      let seccionesContent: any[] = [];
      let subsectionInfo: any = null;
      await queue.addAll(
        listaProcedimiento.map((elem, lpIndex) => async () => {
          //console.log('elem', {elem});
          const {
            tipo,
            additionalPhotos,
            comentario,
            pregunta,
            tipoRespuesta,
            nPregunta,
            alternativas,
            yesNo,
            inspectionCheck,
            multipleChoice,
            textField,
            numberField,
            checkbox,
            date,
            signature,
            files,
            images,
            time,
            location,
            perfilEmpleado,
            thirdParty,
            asset,
            multiCheckbox,
            meterReading,
            year,
            month,
            day,
            ammount,
            haveCriticality,
            criticality,
            allowOthersOption,
            multipleChoiceOthers,
            multiCheckboxOthers,
            //alternativasCheckbox
          } = elem;
          let headerRows = 1;
          let preguntaContent: any = {
            colSpan: 2,
            table: {
              headerRows: headerRows,
              dontBreakRows: true,
              keepWithHeaderRows: 1,
              widths: [220, 300],
              body: [],
            },
            layout: {
              hLineColor: function (i, node) {
                return 'white';
              },
              hLineWidth: function (i, node) {
                return 1;
              },
              vLineWidth: function (i) {
                return 0;
              },
              paddingTop: function (i, node) {
                if (i === 0) {
                  return 1;
                }
                return 0;
              },
              paddingBottom: function (i, node) {
                return 2;
              },
            },
          };
          if (tipo === 'seccion') {
            if (elem.categoria) {
              const subseccion = listaProcedimiento[lpIndex + 1];
              const seccionMargin = [0, 10, 0, subseccion?.categoria ? 5 : 0];
              seccionesContent.push({text: elem.categoria, alignment: 'center', bold: true, fontSize: 12, margin: seccionMargin});
            }
          } else if (tipo === 'subseccion') {
            // console.log('%c⧭', 'color: #408059', {...subsectionInfo});
            if (subsectionInfo && subsectionInfo.count - subsectionInfo.current === 0) {
              subsectionInfo = null;
            }
            const isDynamic = elem.isDynamic;
            if (isDynamic) {
              if (!subsectionInfo) {
                subsectionInfo = {...elem};
                const nextSectionIndex = listaProcedimiento.findIndex((item: any, i) => i > lpIndex && item.tipo === 'seccion');
                const nextSection = nextSectionIndex !== -1 ? nextSectionIndex : listaProcedimiento.length;
                const nextSubsectionIndex = listaProcedimiento.findIndex(
                  (item: any, i) => i > lpIndex && item.tipo === 'subseccion',
                );
                const nextSubsection = nextSubsectionIndex !== -1 ? nextSubsectionIndex : nextSection;
                const subsectionItems = listaProcedimiento.slice(lpIndex + 1, nextSubsection);
                const allSubsections = listaProcedimiento.filter(
                  (item: any) => item.tipo === 'subseccion' && item.listIndex === elem.listIndex,
                );
                subsectionInfo.subsectionItems = subsectionItems;
                subsectionInfo.count = allSubsections.length;
                subsectionInfo.columnCount = subsectionItems.length;
                subsectionInfo.current = 1;
                subsectionInfo.currentRow = [text(subsectionInfo.current, {bold: true})];
                seccionesContent.push({
                  table: {
                    headerRows: 2,
                    dontBreakRows: false,
                    keepWithHeaderRows: 2,
                    widths: [30, ...subsectionItems.map(() => '*')],
                    body: [
                      [
                        {
                          text: elem.categoria,
                          alignment: 'center',
                          bold: true,
                          fontSize: 11,
                          colSpan: subsectionInfo.columnCount + 1,
                          borderColor: [null, '#fff'],
                        },
                        ...subsectionItems.map(() => ({text: '', borderColor: [null, '#fff']})),
                      ],
                      [
                        {text: 'N°', bold: true, borderColor: [null, '#000'], fontSize: 10},
                        ...subsectionItems.map((question) => ({
                          text: question.pregunta?.label,
                          borderColor: [null, '#000'],
                          fontSize: 10,
                        })),
                      ],
                      subsectionInfo.currentRow,
                    ],
                  },
                  margin: [0, -5, 0, 14],
                  layout: {
                    hLineColor: function (i, node) {
                      return '#E8E8E8';
                    },
                    hLineWidth: function (i, node) {
                      return 1;
                    },
                    vLineWidth: function (i) {
                      return 0;
                    },
                    paddingTop: function (i, node) {
                      return 4;
                    },
                    paddingBottom: function (i, node) {
                      return 3;
                    },
                  },
                });
              } else {
                subsectionInfo.current++;
                subsectionInfo.currentRow = [text(subsectionInfo.current, {bold: true})];
                const currentSeccion = seccionesContent[seccionesContent.length - 1];
                currentSeccion.table.body.push(subsectionInfo.currentRow);
              }
            } else {
              seccionesContent.push({
                table: {
                  headerRows: 0,
                  widths: [220, 300],
                  body: [
                    [{text: elem.categoria, alignment: 'center', bold: true, fontSize: 11, colSpan: 2}, {}],
                    [
                      {text: '', borderColor: [null, '#000']},
                      {text: '', borderColor: [null, '#000']},
                    ],
                  ],
                },
                margin: [0, 0, 0, 14],
                layout: {
                  hLineColor: function (i, node) {
                    return 'white';
                  },
                  hLineWidth: function (i, node) {
                    return 1;
                  },
                  vLineWidth: function (i) {
                    return 0;
                  },
                  paddingTop: function (i, node) {
                    return 0;
                  },
                  paddingBottom: function (i, node) {
                    return 5;
                  },
                },
              });
            }
          } else if (tipo === 'pregunta') {
            const dynamicMargin = [nPregunta > 9 ? 13 : 10, 0, 0, 0];
            // get current seccion content
            const currentSeccion = seccionesContent[seccionesContent.length - 1];
            const tituloPregunta = pregunta.titulo || pregunta.label;
            const rows: any[][] = [
              [
                {
                  text: [{text: `${nPregunta}.`, bold: true}, ` ${tituloPregunta}`],
                  fontSize: 9,
                  margin: [0, textMargin + 1, 0, 0],
                },
              ],
            ];
            switch (tipoRespuesta) {
              case 'files':
                if (subsectionInfo) {
                  subsectionInfo.currentRow.push({text: nPregunta, alignment: 'right'});
                }
                rows[0].push(text(`${files?.length ? files.map((f) => f.name).join(', ') : ''}`, {margin: replyMargin}));
                if (images?.length) {
                  //rows[0][0].rowSpan = (rows[0][0].rowSpan || 1) + 1;
                  //rows[0][0].text += '\n  Imágenes: ';
                  //rows.push(['','', '']);
                  rows.push([text('Imágenes: ', {border: [true, false, false, false], margin: dynamicMargin}), {}]); // mejor una fila directamente por mientras
                  headerRows++;
                  (await imgsGrid(images, {margin: dynamicMargin})).forEach((img) => {
                    rows.push([{...img, colSpan: 2}, {}]);
                  });
                }
                break;
              case 'ammount':
                rows[0].push(text(`${ammount || ''}`, {margin: replyMargin}));
                break;
              case 'day':
                const days = {
                  monday: 'Lunes',
                  tuesday: 'Martes',
                  wednesday: 'Miercoles',
                  thursday: 'Jueves',
                  friday: 'Viernes',
                  saturday: 'Sabado',
                  sunday: 'Domingo',
                };
                rows[0].push(text(`${days[day] || ''}`, {margin: replyMargin}));
                break;
              case 'month':
                const months = {
                  january: 'Enero',
                  february: 'Febrero',
                  march: 'Marzo',
                  april: 'Abril',
                  may: 'Mayo',
                  june: 'Junio',
                  july: 'Julio',
                  august: 'Agosto',
                  september: 'Septiembre',
                  october: 'Octubre',
                  november: 'Noviembre',
                  december: 'Diciembre',
                };
                rows[0].push(text(`${months[month] || ''}`, {margin: replyMargin}));
                break;
              case 'year':
                rows[0].push(text(`${year || ''}`, {margin: replyMargin}));
                break;
              case 'meterReading':
                rows[0].push(text(`${meterReading || ''}`, {margin: replyMargin}));
                break;
              case 'textField':
                rows[0].push(text(`${textField || ''}`, {margin: replyMargin}));
                break;
              case 'numberField':
                rows[0].push(text(`${numberField || ''}`, {margin: replyMargin}));
                break;
              case 'checkbox':
                rows[0].push(text(`${checkbox ? 'Sí' : 'No'}`, {margin: replyMargin}));
                break;
              case 'date':
                rows[0].push(text(`${date ? moment(date).format('DD-MM-YYYY') : ''}`, {margin: replyMargin}));
                break;
              case 'signature':
                if (signature) rows[0].push({columns: [{image: signature, fit: [100, 100]}], margin: replyMargin});
                else rows[0].push(text('', {margin: replyMargin}));
                break;
              case 'inspectionCheck':
                rows[0].push({
                  layout: (cell, row) => {
                    return {
                      paddingTop: function (i, node) {
                        return 0;
                      },
                      paddingBottom: function (i, node) {
                        return 0;
                      },
                    };
                  },
                  margin: [10, 0, 0, 2],
                  table: {
                    body: [
                      [
                        {text: 'Aprobado', fillColor: inspectionCheck === 'Aprobado' ? 'green' : 'white', fontSize: 8},
                        {text: 'Observado', fillColor: inspectionCheck === 'Observado' ? 'orange' : 'white', fontSize: 8},
                        {text: 'Rechazado', fillColor: inspectionCheck === 'Rechazado' ? 'red' : 'white', fontSize: 8},
                      ],
                    ],
                  },
                });
                break;
              case 'yesNo':
                rows[0].push(text(`${yesNo || ''}`, {margin: replyMargin}));
                break;
              case 'time':
                rows[0].push(text(`${time ? moment(time).format('HH:mm') : ''}`, {margin: replyMargin}));
                break;
              case 'location':
                let locations = location ? (Array.isArray(location) ? location : [location]) : undefined;
                rows[0].push(text(`${locations?.map((l) => l.label).join(', ') || ''}`, {margin: replyMargin}));
                break;
              case 'perfilEmpleado':
                let perfilEmpleados = perfilEmpleado
                  ? Array.isArray(perfilEmpleado)
                    ? perfilEmpleado
                    : [perfilEmpleado]
                  : undefined;
                rows[0].push(text(`${perfilEmpleados?.map((l) => l.label).join(', ') || ''}`, {margin: replyMargin}));
                break;
              case 'thirdParty':
                let thirdParties = thirdParty ? (Array.isArray(thirdParty) ? thirdParty : [thirdParty]) : undefined;
                rows[0].push(text(`${thirdParties?.map((l) => l.label).join(', ') || ''}`, {margin: replyMargin}));
                break;
              case 'asset':
                let assets = asset ? (Array.isArray(asset) ? asset : [asset]) : undefined;
                rows[0].push(text(`${assets?.map((l) => l.label).join(', ') || ''}`, {margin: replyMargin}));
                break;
              case 'multiCheckbox':
                const answerList: any = [];
                if (multiCheckbox?.length) {
                  multiCheckbox?.forEach((a, i) => {
                    if ((isBlumarPT && a.value) || !isBlumarPT) {
                      const label =
                        a.value && a.label === 'Otros' && allowOthersOption && multiCheckboxOthers
                          ? `${a.label}: ${multiCheckboxOthers}`
                          : a.label;
                      answerList.push([
                        {},
                        {
                          columns: [
                            {
                              stack: [
                                {text: a.value ? '√' : ' ', margin: [0, 0, 0, -10]},
                                {
                                  canvas: [
                                    {
                                      type: 'polyline',
                                      lineWidth: 1,
                                      closePath: true,
                                      points: [
                                        {x: 0, y: 0},
                                        {x: 8, y: 0},
                                        {x: 8, y: 8},
                                        {x: 0, y: 8},
                                      ],
                                    },
                                  ],
                                },
                              ],
                              width: 5,
                              margin: [10, 1],
                            },
                            {text: label, fontSize: 8, margin: [0, 3, 0, 0]},
                          ],
                        },
                      ]);
                    }
                  });
                  if (answerList.length > 0) {
                    rows[0].push(answerList);
                  }

                  //rows.push([{text:'', margin:[0,-5,0,0], colSpan:2},{}]);
                } else {
                  rows[0].push(text('No seleccionado', {margin: replyMargin}));
                }
                break;
              case 'multipleChoice':
                rows[0].push(
                  text(
                    `${
                      allowOthersOption && multipleChoiceOthers
                        ? `Otros: ${multipleChoiceOthers}`
                        : alternativas.filter((a) => a.id === multipleChoice).map((a) => a.label || '')
                    }`,
                    {margin: replyMargin},
                  ),
                );
                break;
              default:
                rows[0].push(text(`Tipo respuesta <${tipoRespuesta}> no definido.`, {margin: replyMargin}));
                break;
            }

            if (haveCriticality) {
              headerRows++;
              rows.push([
                text(`Criticidad:`, {width: 'auto', margin: dynamicMargin}),
                {
                  margin: [10, 0, 0, 0],
                  layout: (cell, row) => {
                    return {
                      paddingTop: function (i, node) {
                        return 0;
                      },
                      paddingBottom: function (i, node) {
                        return 0;
                      },
                    };
                  },
                  table: {
                    body: [
                      [
                        {text: 'Baja', fillColor: criticality === 'Baja' ? 'green' : 'white', fontSize: 8},
                        {text: 'Media', fillColor: criticality === 'Media' ? 'orange' : 'white', fontSize: 8},
                        {text: 'Alta', fillColor: criticality === 'Alta' ? 'red' : 'white', fontSize: 8},
                      ],
                    ],
                  },
                },
              ]);
            }
            if (comentario) {
              headerRows++;
              rows.push([
                text(`Comentarios adicionales: `, {margin: [nPregunta > 9 ? 13 : 10, 3, 0, 1]}),
                text(`${comentario}`, {margin: [10, 3, 0, 1]}),
              ]);
            }
            if (additionalPhotos?.length) {
              headerRows++;
              rows.push([text('Fotos adicionales: ', {margin: dynamicMargin}), {}]);
              //rows.push([{columns: await imgs(additionalPhotos), margin: listMargins, colSpan: 2}, {}]);
              (await imgsGrid(additionalPhotos, {margin: dynamicMargin, columnGap})).forEach((img) => {
                rows.push([{...img, colSpan: 2}, {}]);
              });
            }
            //rows.push([{}, {}]);
            // rows.push([
            //   {text: '', borderColor: [null, '#E8E8E8']},
            //   {text: '', borderColor: [null, '#E8E8E8']},
            // ]);
            if (subsectionInfo) {
              subsectionInfo.currentRow.push({text: rows[0][1], alignment: 'left'});
            } else {
              preguntaContent.table.headerRows = headerRows;
              preguntaContent.table.body.push(...rows);
              currentSeccion.table.body.push(
                [preguntaContent, {}],
                [
                  {text: '', borderColor: [null, '#E8E8E8']},
                  {text: '', borderColor: [null, '#E8E8E8']},
                ],
              );
            }
          }
        }),
      );
      content.push(...seccionesContent);
    }

    // console.log('%c⧭ content', 'color: #ffcc00', content);

    //detalles generales
    const detallesGeneralesBody: any = [];
    if (tipoVisualizacion === 'Estandar') {
      //if (comentarioEnviado)
      detallesGeneralesBody.push([
        {
          text: 'Detalles Generales',
          bold: true,
          fontSize: 10,
          alignment: 'center',
          colSpan: 2,
        },
        {},
      ]);
      detallesGeneralesBody.push([
        {text: 'Comentario', bold: true, fontSize: 10, alignment: 'left'},
        {text: comentarioEnviado ? comentarioEnviado : 'Sin comentarios', fontSize: 10, alignment: 'left'},
      ]);
    }
    //if (comentarioEnviado) content.push(text('Detalles Generales: ' + comentarioEnviado));
    if (documentosEnviados?.length || tipoVisualizacion === 'Estandar') {
      detallesGeneralesBody.push([
        {text: 'Documentos', bold: true, fontSize: 10, alignment: 'left'},
        {
          text: documentosEnviados?.length > 0 ? documentosEnviados.map((d) => d.name).join(', ') : 'Sin documentos',
          fontSize: 10,
          alignment: 'left',
        },
      ]);
    }
    if (fotosEnviadas?.length || tipoVisualizacion === 'Estandar') {
      detallesGeneralesBody.push([
        {
          text: 'Imágenes',
          bold: true,
          fontSize: 10,
          alignment: 'left',
          border: [true, true, !(fotosEnviadas?.length > 0), !(fotosEnviadas?.length > 0)],
        },
        {
          text: fotosEnviadas?.length > 0 ? '' : 'Sin imágenes',
          fontSize: 10,
          alignment: 'left',
          border: [!(fotosEnviadas?.length > 0), true, true, !(fotosEnviadas?.length > 0)],
        },
      ]);
      if (fotosEnviadas?.length) {
        const imgsRow: any = [];
        (await imgsGrid(fotosEnviadas, {margin: [0, 5]}, imgsPerRow, {fit: imgFit})).forEach((img) => {
          imgsRow.push([{...img, colSpan: 2}, {}]);
        }),
          detallesGeneralesBody.push([
            {
              stack: imgsRow,
              colSpan: 2,
              border: [true, false, true, true],
            },
            {},
          ]);
      }
    }
    if (detallesGeneralesBody?.length) {
      content.push(
        infoTable(
          {
            widths: [80, '*'],
            body: detallesGeneralesBody,
          },
          {margin: [0, 25]},
        ),
      );
    }
    //fin detalles generales

    if (firmas?.length) {
      const columns: any = [];
      firmas?.forEach((firma) => {
        if (!firma.firma) {
          // columns.push(text('Firma de ' + firma.responsable?.fullName || 'Nombre no registrado.', {fontSize: 9}));
          // columns.push(text('Firma no encontrada', {fontSize: 9}));
          return;
        }
        const stack = [
          {
            layout: {
              defaultBorder: false,
              paddingLeft: function (i, node) {
                return 0;
              },
              paddingRight: function (i, node) {
                return 0;
              },
              paddingTop: function (i, node) {
                return 0;
              },
              paddingBottom: function (i, node) {
                return 0;
              },
              hLineColor: function (i, node) {
                return '#E8E8E8';
              },
              vLineColor: function (i, node) {
                return '#E8E8E8';
              },
              hLineWidth: function (i, node) {
                return 0.3;
              },
              vLineWidth: function (i) {
                return 0.3;
              },
            },
            table: {
              widths: ['*', 'auto', '*'],
              body: [[{}, {image: firma.firma, fit: [200, 200], border: [true, true, true, true]}, {}]],
            },
          },
          {text: firma.responsable?.fullName || 'Nombre no registrado', fontSize: 9},
          {text: firma.responsable?.puesto?.nombre || '', fontSize: 9},
        ];
        columns.push({
          stack,
          margin: 1,
          alignment: 'center',
        });
      });
      //console.log('%c⧭ columns', 'color: #aa0041', columns);
      if (columns.length) {
        const tablaFirma = {
          layout: 'table',
          width: '*',
          table: {
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            body: [
              [{text: 'Firma de validación ', bold: true, fontSize: 10, alignment: 'center'}],
              ...breakArray(columns, 2).map((columns) => [
                {columns, margin: [0, 25, 0, 25], border: [false, false, false, false]},
              ]),
            ],
            widths: ['*'],
          },
          margin: [0, 10],
          // layout: {
          //   vLineWidth: function (i) {
          //     return 0;
          //   },
          //   hLineWidth: function (i) {
          //     return 0;
          //   },
          // },
        };
        content.push(tablaFirma);
      }
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let dd: any = {
      content,
      info: {
        title: tituloPdf,
        author: 'stemwaste.cl',
      },
      styles: {
        tableExample: {
          margin: [-20, 5, 5, 0],
        },
        firmaValidacion: {
          margin: [0, 0, 0, 0],
        },
      },
      defaultStyle: {
        alignment: 'left',
        fontSize: 11,
        bold: false,
        columnGap: 20,
      },
      // a string or { width: number, height: number }
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [30, 100, 30, 50],
      header: function (currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let hd: any = {
          margin: [30, 20, 30, 40],
          columns: [],
        };
        hd.columns.push(tituloTable);
        // if (logo) hd.columns.push({maxWidth: 100, image: logo, maxHeight: 40});
        // else hd.columns.push({text: tenantName});
        //let stack: any = [];
        //if(fechaFin) stack.push({ text: [{text: 'Fecha Ejecutada: ', fontSize: 12, bold: true}, fechaFin], alignment: 'right' });
        // if (fechaIni) stack.push({ text: fechaIni, alignment: 'right' });
        // if (fechaVencimiento) stack.push({ text: fechaVencimiento, alignment: 'right' });
        //hd.columns.push({stack});
        return [hd];
      },
      footer: function (currentPage, pageCount) {
        const footerText = `Página ${currentPage} de ${pageCount} `;
        const byStemwaste = 'Generado con stemwaste.cl';
        let fd: any = {
          margin: [30, 20, 30, 10],
          columns: [
            {text: footerText, alignment: 'left', color: '#303030', fontSize: 8},
            {text: ''},
            {text: byStemwaste, alignment: 'right', color: '#A0A0A0', fontSize: 8},
          ],
        };
        return [fd];
      },
    };
    // await new Promise<void>((resolve) => {
    //   pdfMake.createPdf(dd).download(tituloPdf, () => {
    //     resolve();
    //   });
    // });
    //console.log('%c⧭ dd', 'color: #aa0041', dd);
    pdfMake.tableLayouts = {
      table: {
        hLineWidth: function (i, node) {
          return 0.5;
        },
        vLineWidth: function (i) {
          return 0.5;
        },
        hLineColor: function (i) {
          return '#080808';
        },
        vLineColor: function (i) {
          return '#080808';
        },
      },
      headerTable: {
        hLineWidth: function (i, node) {
          return 0.5;
        },
        vLineWidth: function (i) {
          return 0.5;
        },
        hLineColor: function (i) {
          return '#080808';
        },
        vLineColor: function (i) {
          return '#080808';
        },
        paddingTop: (rowIndex, node) => {
          if (rowIndex > 0) return 0;
          const rowNode = node.table.body[rowIndex];
          rowNode.forEach((cellNode, cellIndex) => {
            /* Hack para alinear verticalmente al centro en esta libreria */
            if (cellIndex > 1) return 0;
            const maxWidth = node.table.widths[1]._calcWidth;
            const text = cellNode._inlines;
            const image = !!cellNode.image;
            let itemHeight: any,
              margin: any,
              cell: any = null;
            if (image) {
              itemHeight = cellNode._height;
              margin = (66 - itemHeight) / 2;
              cell = cellNode;
              //console.log('%c⧭ image', 'color: #994d75', {rowIndex, cellIndex, image, cell, margin, itemHeight});
            }
            if (text) {
              let usedWidth = 0;
              let maxHeight = 0;
              let lastHadLineEnd = false;
              text.forEach((inline, i) => {
                // console.log('%c⧭ inline', 'color: #994d75', {
                //   inline,
                //   usedWidth,
                //   maxWidth,
                //   lastHadLineEnd,
                //   itemHeight,
                //   maxHeight,
                // });
                usedWidth += inline.width;
                if (usedWidth > maxWidth || lastHadLineEnd) {
                  maxHeight += inline.height;
                  usedWidth = inline.width;
                } else {
                  maxHeight = Math.max(inline.height, maxHeight);
                }
                lastHadLineEnd = !!inline.lineEnd;
              });

              itemHeight = maxHeight;

              margin = (66 - itemHeight) / 2;
              cell = cellNode;

              // console.log('%c⧭ text', 'color: #994d75', {
              //   rowIndex,
              //   cellIndex,
              //   text,
              //   cell,
              //   margin,
              //   itemHeight,
              // });
            }
            // set cell margin
            if (cell) {
              if (cell._margin) {
                cell._margin[1] = margin;
              } else {
                cell._margin = [0, margin, 0, 0];
              }
            }
          });
          return 0;
        },
      },
    };
    const pdf = pdfMake.createPdf(dd);
    if (open) {
      pdf.open();
    }
    return pdf;
  },
};
export default tareaPdf;
